@mixin secondary_button($width) {
    width: $width;
    height: 48px;
    background-color: $secondary-button-color;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: 600;
} 

@mixin secondary_button_x_auto($padding) {
    width: auto;
    padding: $padding;
    background-color: $secondary-button-color;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: 600;
}

@mixin primary_button_x_auto($padding){
    width: auto;
    padding: $padding;
    background-color: white;
    border: 1px solid $secondary-button-color;
    border-radius: 10px;
    color: $secondary-button-color;
    cursor: pointer;
    font-weight: 600;
}