//* {
//  margin: 0;
//  padding: 0;
//  box-sizing: border-box;
//  font-family: "Montserrat";
//}
//
//$secondary-background: #008160;
//$secondary-button-color: #00c191;
//$secondary-text-color: #00c191;
//$primary-border: 1px solid #E6E7E8;
//$text-black: #1a2128;
//$text-black-faint: #01101E;
//$icon-black: #212b36;
//
//@import "./mixins";
//@import "./utilities";
//@import "./navbar";
//
///* Chrome, Safari, Edge, Opera */
//input::-webkit-outer-spin-button,
//input::-webkit-inner-spin-button {
//  -webkit-appearance: none;
//  margin: 0;
//}
//
///* Firefox */
//input[type=number] {
//  -moz-appearance: textfield;
//}
//
//
//#logo-link {
//  display: inline-flex;
//  place-items: center;
//  gap: 5px;
//  height: 100%;
//
//  p {
//    font-size: 1.5rem;
//  }
//}
//
//.main-container {
//  width: 100%;
//  height: 100vh;
//}
//
//.cm {
//  max-width: 1440px;
//  padding: 0 2rem;
//}
//
//.sides-container {
//  width: 100%;
//  height: calc(100vh - 5rem);
//}
//
//.right-side-container {
//  background-color: $secondary-background;
//}
//
//.center-right-side-container {
//  width: min(80%, 90%);
//  height: auto;
//  margin-left: min(5%, 67px);
//  margin-right: 67px;
//  top: 47%;
//  transform: translateY(-47%);
//}
//
//.image-rounded-full {
//  border-radius: 100%;
//  width: 40px;
//  height: 40px;
//}
//
//.insight-container {
//  display: grid;
//  gap: 10px;
//  grid-template-columns: 130px 1fr;
//}
//
//.insight-container > p {
//  color: white;
//}
//
//
//
//#login-form {
//  width: 350px;
//  min-height: 400px;
//  top: 10%;
//  left: 50%;
//  transform: translateX(-50%);
//  margin-right: 67px;
//  display: flex;
//  flex-direction: column;
//  place-items: center;
//}
//
//#welcome-container{
//    min-width: 450px;
//    min-height: 400px;
//    max-width: 480px;
//    top: 10%;
//    left: 50%;
//    transform: translateX(-50%);
//    margin-right: 67px;
//    display: flex;
//    flex-direction: column;
//    place-items: center;
//}
//
//.progress-indicator-container{
//    display: grid;
//    height: 15px;
//    width: 450px;
//    max-width: 700px;
//    grid-template-columns: 15px 1fr 15px 1fr 15px;
//    grid-column-gap: 10px;
//}
//
//.progress-stage{
//    border-radius: 100%;
//    background: #CCCFD2;
//}
//
//.progress-stage-active{
//    background: $secondary-text-color;
//}
//
//.progress-stage-finished{
//    border-radius: 0;
//    background: none;
//    transform: scale(1.5);
//}
//
//.progress-track{
//    border: 1px solid #F7F7F7
//}
//
//.progress-track-finished{
//    border: 1px solid $secondary-text-color
//}
//
//.login-logo {
//  width: 33px;
//  height: 33px;
//}
//
////input[type="email"],
////input[type="password"],
////input[type="text"] {
////  border: $primary-border;
////  width: 320px;
////  height: 48px;
////  padding: 5px 10px;
////  border-radius: 10px;
////}
//
////input::placeholder{
////    font-size: 14px;
////}
//
//.verification-input-container{
//    display: grid;
//    grid-template-columns: repeat(5, 56px);
//    grid-gap: 10px;
//}
//
//.verification-input{
//    border: $primary-border;
//    width: 56px;
//    height: 65px;
//    padding: 5px 10px;
//    border-radius: 10px;
//    background-color: #F7F7F7;
//}
//
//
//
//.password-eye {
//  right: 12px;
//  top: 50%;
//  transform: translateY(-50%);
//}
//
//input.input-with-icon-left{
//    padding-left: 37px ;
//}
//
//.input-icon{
//    left: 12px;
//    top: 50%;
//    transform: translateY(-50%);
//}
//
////input:focus {
////  outline: none;
////  transition: all 1s ease;
////  border: 1px gray solid;
////}
//
//.input-group {
//  width: auto;
//  height: auto;
//}
//
//.install-width-section{
//    width: 408.5px;
//    max-width: 408.5px;
//    display:block
//}
//
//.install-cards{
//    // max-width: 408.5px;
//    grid-template-columns: auto  1fr;
//    grid-column-gap: 15px;
//    padding: 12px 48px 12px 13px;
//}
//
////@media (max-width: 1800px) {
////  input[type="email"],
////  input[type="password"],
////  input[type="text"] {
////    border: $primary-border;
////    width: 280px;
////    height: 40px;
////    padding: 5px 10px;
////    border-radius: 10px;
////  }
//
//  input::placeholder{
//    font-size: 12px;
// }
//
// #welcome-container{
//  width: 350px;
//  min-width: 350px;
// }
//
// .progress-indicator-container{
//  width: 350px;
//  grid-template-columns: 10px 1fr 10px 1fr 10px;
//  height: 10px;
//
// }
//
// .install-width-section{
//  width: 350px;
// }
//
// .progress-stage-finished{
//  transform: scale(1.2);
// }
//
// #logo-link p{
//  font-size: 1.3rem
// };
//
// .sides-container{
//  height: calc(100vh - 4rem);
// }
//
// input.input-with-icon-left{
//  padding-left: 37px;
//}
//
