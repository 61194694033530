* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Inter", sans-serif;*/
}

.text-align-left {
  text-align: left;
}

.absolute {
  position: absolute;
}

.text-align-center {
  text-align: center;
}

.text-1 {
  font-size: 0.875rem;
  font-weight: 400;
}

.text-1-heavy {
  font-weight: 700;
  font-size: 0.875rem;
}

.text-2 {
  font-size: 1rem;
  font-weight: 400;
}

.text-3 {
  font-size: 1.75rem;
  font-weight: 700;
}

.text-4 {
  font-size: 2.525rem;
  font-weight: 600;
}

.text-5 {
  font-size: 3.5rem;
  font-weight: 700;
}

.black {
  color: #1a2128;
}

.text-gray {
  color: #677078;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.grid {
  display: grid;
}

.grid1-2 {
  grid-template-columns: 50% 50%;
}

.align-self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.text-secondary {
  color: #00c191;
}

.secondary-button {
  width: 280px;
  height: 48px;
  background-color: #00c191;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.secondary-button-x-auto {
  width: auto;
  padding: 4px 16px;
  background-color: #00c191;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.primary-button-x-auto {
  width: auto;
  padding: 4px 16px;
  background-color: white;
  border: 1px solid #00c191;
  border-radius: 10px;
  color: #00c191;
  cursor: pointer;
  font-weight: 600;
}

.white {
  color: white;
}

.card-group {
  border-radius: 10px;
  border: 1px solid #E6E7E8;
  box-shadow: 0px 2px 6px rgba(26, 40, 53, 0.08);
  border-radius: 10px;
}

.black-faint {
  color: #01101E;
}

.block {
  display: block;
}

hr .divider {
  border: 1px solid #E6E7E8;
}

.black-faint-2 {
  color: #434D56;
}

.input-text-area-resize-none {
  resize: none;
  width: 100%;
  border: 1px solid #E6E7E8;
  padding: 5px 10px;
  border-radius: 10px;
}

.input-text-area-resize-none:focus {
  outline: none;
}

.align-self-start {
  align-self: start;
}

@media (max-width: 1800px) {
  .text-1, .text-1-heavy {
    font-size: 0.75rem;
  }
  .text-2 {
    font-size: 0.8rem;
  }
  .text-3 {
    font-size: 1.3rem;
  }
  .text-4 {
    font-size: 2rem;
  }
  .text-5 {
    font-size: 2.813rem;
  }
}
.navbar {
  min-height: 64px;
  max-height: 80px;
  height: 80px;
  width: 100%;
  box-shadow: 0px 17px 33px -2px rgba(28, 39, 49, 0.05);
}

.logo {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1800px) {
  .navbar {
    height: 64px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#logo-link {
  display: inline-flex;
  place-items: center;
  gap: 5px;
  height: 100%;
}
#logo-link p {
  font-size: 1.5rem;
}

.main-container {
  width: 100%;
  max-height: 100vh;
}

.cm {
  max-width: 1440px;
  padding: 0 2rem;
}

.sides-container {
  width: 100%;
  height: calc(100vh - 3.8rem);
}

.right-side-container {
  background-color: #008160;
}

.center-right-side-container {
  width: 80%;
  height: auto;
  margin-left: min(5%, 67px);
  margin-right: 67px;
  top: 47%;
  transform: translateY(-47%);
}

.image-rounded-full {
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.insight-container {
  display: grid;
  gap: 10px;
  grid-template-columns: 130px 1fr;
}

.insight-container > p {
  color: white;
}

/*#login-form {*/
/*  width: 350px;*/
/*  min-height: 400px;*/
/*  top: 10%;*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*  margin-right: 67px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  place-items: center;*/
/*}*/

#welcome-container {
  min-width: 450px;
  min-height: 400px;
  max-width: 480px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  margin-right: 67px;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.progress-indicator-container {
  display: grid;
  height: 15px;
  width: 100%;
  max-width: 100%;
  grid-template-columns: 15px 1fr 15px 1fr 15px;
  grid-column-gap: 10px;
}

.progress-stage {
  border-radius: 100%;
  background: #CCCFD2;
}

.progress-stage-active {
  background: #00c191;
}

.progress-stage-finished {
  border-radius: 0;
  background: none;
  transform: scale(1.5);
}

.progress-track {
  border: 1px solid #E6E7E8;
}

.progress-track-finished {
  border: 2px solid #008160;
}

/*.login-logo {*/
/*  width: 33px;*/
/*  height: 33px;*/
/*}*/

/*input[type=email],*/
/*input[type=password],*/
/*input[type=text] {*/
/*  border: 1px solid #E6E7E8;*/
/*  width: 320px;*/
/*  height: 48px;*/
/*  padding: 5px 10px;*/
/*  border-radius: 10px;*/
/*}*/

/*input::-moz-placeholder {*/
/*  font-size: 14px;*/
/*}*/

/*input:-ms-input-placeholder {*/
/*  font-size: 14px;*/
/*}*/

/*input::placeholder {*/
/*  font-size: 14px;*/
/*}*/

.verification-input-container {
  display: grid;
  grid-template-columns: repeat(5, 56px);
  grid-gap: 10px;
}

.verification-input {
  border: 1px solid #E6E7E8;
  width: 56px;
  height: 65px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #F7F7F7;
}

/*.password-eye {*/
/*  right: 12px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*}*/

/*input.input-with-icon-left {*/
/*  padding-left: 37px;*/
/*}*/

.input-icon {
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

input:focus {
  outline: none;
  transition: all 1s ease;
  /*border: 1px gray solid;*/
}

.input-group {
  width: auto;
  height: auto;
}

.install-width-section {
  width: 408.5px;
  max-width: 408.5px;
  display: block;
}

.install-cards {
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  padding: 12px 48px 12px 13px;
}

@media (max-width: 1800px) {
/*  input[type=email],*/
/*input[type=password],*/
/*input[type=text] {*/
/*    border: 1px solid #E6E7E8;*/
/*    width: 280px;*/
/*    height: 40px;*/
/*    padding: 5px 10px;*/
/*    border-radius: 10px;*/
/*  }*/
  /*input::-moz-placeholder {*/
  /*  font-size: 12px;*/
  /*}*/
  /*input:-ms-input-placeholder {*/
  /*  font-size: 12px;*/
  /*}*/
  /*input::placeholder {*/
  /*  font-size: 12px;*/
  /*}*/
  #welcome-container {
    width: 350px;
    min-width: 350px;
  }
  .progress-indicator-container {
    width: 350px;
    grid-template-columns: 10px 1fr 10px 1fr 10px;
    height: 10px;
  }
  .install-width-section {
    width: 350px;
  }
  .progress-stage-finished {
    transform: scale(1.2);
  }
  #logo-link p {
    font-size: 1.3rem;
  }
  /*input.input-with-icon-left {*/
  /*  padding-left: 37px;*/
  /*}*/
}/*# sourceMappingURL=styles.css.map */
