.text-align-left {
  text-align: left;
}

.absolute {
  position: absolute;
}

.text-align-center {
  text-align: center;
}


.text-1 {
  font-size: 0.875rem;
  font-weight: 400;
}

.text-1-heavy {
  font-weight: 700;
  font-size: 0.875rem;
}

.text-2 {
  font-size: 1rem;
  font-weight: 400;
}

.text-3 {
  font-size: 1.75rem;
  font-weight: 700;
}

.text-4 {
  font-size: 2.525rem;
  font-weight: 600;
}

.text-5 {
  font-size: 3.5rem;
  font-weight: 700;
}

.black {
  color: $text-black;
}

.text-gray {
  color: #677078;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.grid {
  display: grid;
}

.grid1-2 {
  grid-template-columns: 50% 50%;
}

.align-self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.text-secondary {
  color: $secondary-text-color;
}

.secondary-button {
  @include secondary_button(280px);
}

.secondary-button-x-auto {
  @include secondary_button_x_auto(4px 16px);
}

.primary-button-x-auto {
  @include primary_button_x_auto(4px 16px);
}

.white {
  color: white;
}

.card-group {
  border-radius: 10px;
  border: $primary-border;
  box-shadow: 0px 2px 6px rgba(26, 40, 53, 0.08);
border-radius: 10px;
}

.black-faint {
  color: $text-black-faint;
}

.block {
  display: block;
}

hr .divider{
    border: $primary-border;
}

.black-faint-2{
    color: #434D56;
}

.input-text-area-resize-none{
    resize: none;
    width: 100%;
    border: $primary-border;
    padding: 5px 10px;
    border-radius: 10px;
}

.input-text-area-resize-none:focus{
    outline: none;
}

.align-self-start{
    align-self: start;
}

@media (max-width: 1800px) {
  .text-1, .text-1-heavy{
    font-size: 0.75rem;
  }

  .text-2{
    font-size: 0.80rem;
  }

  .text-3{
    font-size: 1.3rem;
  }

  .text-4{
    font-size: 2rem
  }

  .text-5{
    font-size: 2.813rem;
  }

}
