.navbar{
    min-height: 64px;
    max-height: 80px;
    height: 80px;
    width: 100%;
    box-shadow: 0px 17px 33px -2px rgba(28, 39, 49, 0.05);
}

.logo{
  top: 50%;
  transform: translateY(-50%);  
}

@media (max-width: 1800px){
  .navbar{
    height: 64px;

  }
}