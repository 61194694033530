@import "~@flaticon/flaticon-uicons/css/all/all";
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CircularStd-Medium';
  src: local('CircularStd-Medium'), url('/assets/fonts/circular-std-medium-500.ttf') format('truetype');
}

.grecaptcha-badge { visibility: hidden; }

/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #00c191;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #00c191;
}

.sticky-component {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.trunc {
  white-space: nowrap;
  width: 100%;                   /* IE6 needs any width */
  overflow: hidden;              /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis;    /* Opera < 11*/
  text-overflow:    ellipsis;    /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}

.slim-scroll::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #eeeeee !important;
}

.slim-scroll::-webkit-scrollbar{
  width: 6px;
  height: 6px;
  background-color: lightgray !important;
}

.slim-scroll::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: #CCCFD2 !important;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loading {
  font-size: inherit;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
